import React from 'react'
import styled from 'styled-components'
import { bottomBorder } from '../../utils/bottomBorder'

const DocumentList = styled.section`
  margin-bottom: ${({ theme }) => theme.rhythm(1)};
  ${bottomBorder}

  article:last-child {
    margin-bottom: 0;
  }
`

export function ItemsList({
  header,
  items,
  component: Component,
  keyName,
  after,
}) {
  return (
    <DocumentList>
      {header}
      {items.map(({ node }) => {
        return (
          <Component
            item={node}
            key={keyName ? node[keyName] : node.slug.current}
          />
        )
      })}
      {after}
    </DocumentList>
  )
}
