import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image'
import styled from 'styled-components'
import { PortableText } from '../content/PortableText'
import { bottomBorder } from '../../utils/bottomBorder'
import { SocialLinks } from '../content/SocialLinks'
import { H1 } from '../styled/H1'
import { postExcerpt } from '../../utils/postExcerpt'
import { PageLink } from '../styled/PageLink'

const StyledBio = styled.section`
  display: flex;
  margin-bottom: ${({ theme }) => theme.rhythm(1)};
  align-items: center;
  ${bottomBorder}
`

const StyledImage = styled(SanityImage)`
  margin-right: ${({ theme }) => theme.rhythm(1 / 2)};
  margin-bottom: 0;
  border-radius: 50%;
`

const AuthorName = styled.h2`
  margin: 0;
  font-weight: 630;
  ${({ theme }) => theme.scale(0.3)}
`

const StyledText = styled(PortableText)`
  p:last-child {
    margin-bottom: 0;
  }
`

export function Bio({ author, isSingle }) {
  const {
    config: { author: defaultAuthor },
  } = useStaticQuery(graphql`
    query DefaultAuthorQuery {
      config: sanityConfig(_id: { eq: "global-config" }) {
        author {
          ...AuthorBio
        }
      }
    }
  `)

  if (!author) {
    author = defaultAuthor
  }

  const authorImage = (
    <StyledImage
      className="u-photo"
      {...author.image}
      width={48}
      alt={'Avatar for ' + author.name}
      options={{ __experimentalAspectRatio: true }}
    />
  )

  const bioContent = (
    <>
      {isSingle ? (
        <H1>
          About <span className="p-name">{author.name}</span>
        </H1>
      ) : (
        <AuthorName className="p-name">{author.name}</AuthorName>
      )}
      <a href={author.website} className="u-url" rel="me" hidden />
      <p className="p-note" hidden>
        {postExcerpt(author.shortBio, author.bio, 160)}
      </p>
      {isSingle ? (
        <>
          <PortableText text={author.bio} />
          <p>You should follow him on:</p>
        </>
      ) : (
        <>
          <StyledText text={author.shortBio || []} />
          <PageLink to={'/about'}>More about me</PageLink>
        </>
      )}
      <SocialLinks social={author.social} hidden={!isSingle} />
    </>
  )

  return isSingle ? (
    <section className="h-card">{bioContent}</section>
  ) : (
    <StyledBio className="h-card">
      {authorImage}
      <div>{bioContent}</div>
    </StyledBio>
  )
}

export const query = graphql`
  fragment AuthorBio on SanityAuthor {
    name
    slug {
      current
    }
    image {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
    }
    bio: _rawBio
    shortBio: _rawShortBio
    social {
      site
      username
    }
    website
  }
`
