import React from 'react'

const nameMap = {
  facebook: {
    site: 'Facebook',
    username: u => u,
    link: u => u,
  },
  twitter: {
    site: 'Twitter',
    username: u => '@' + u,
    link: u => 'https://twitter.com/' + u,
  },
  github: {
    site: 'Github',
    username: u => '@' + u,
    link: u => 'https://github.com/' + u,
  },
}

export function SocialLinks({ social, hidden }) {
  return (
    <ul hidden={hidden}>
      {social.map(({ site, username }) => {
        const map = nameMap[site]
        return (
          <li key={site + username}>
            <a href={map.link(username)} rel="me">
              {map.username(username) + ' on ' + map.site}
            </a>
          </li>
        )
      })}
    </ul>
  )
}
