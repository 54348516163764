import React from 'react'
import { graphql } from 'gatsby'

import { Bio } from '../components/layout/Bio'
import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { ItemsList } from '../components/page/ItemsList'
import { TweetItem } from '../components/page/TweetItem'
import { H2 } from '../components/styled/H2'
import { PreviousNextPagination } from '../components/generic/PreviousNext'

function Tweets({
  data: {
    allTweets: { edges: tweets },
  },
  pageContext: { currentPage, numPages },
}) {
  return (
    <Layout>
      <SEO
        title="All Tweets"
        currentPage={currentPage}
        numPages={numPages}
      />
      <Bio />
      <ItemsList
        header={<H2>All Tweets</H2>}
        items={tweets}
        component={TweetItem}
        keyName="_id"
      />
      <PreviousNextPagination
        currentPage={currentPage}
        numPages={numPages}
        linkPrefix="/tweets/"
      />
    </Layout>
  )
}

export default Tweets

export const pageQuery = graphql`
  query TweetsQuery($skip: Int!, $limit: Int!) {
    allTweets: allSanityTweet(
      sort: { order: DESC, fields: createdAt }
      skip: $skip
      limit: $limit
      filter: {
        author: { username: { eq: "datapatrick" } }
        isRetweet: { eq: false }
      }
    ) {
      edges {
        node {
          ...TweetItem
        }
      }
    }
  }
`
